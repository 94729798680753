import { isRunningInMetaOSHub } from 'owa-config';
import { lazyGetContext } from 'owa-metaos-app-bootstrap';
import { markFunction } from 'owa-performance';
import { placesSessionDataTable } from './sessionDataDatabase';

import type { SessionData } from 'owa-service/lib/types/SessionData';
import type { TraceErrorObject } from 'owa-trace';

// Core logic extracted from packages/libraries/worker/owa-data-worker-client/src/actions/getOfflineSessionData.ts
// Tweaked to use metaos context and Places' indexDB
export const getOfflineSessionData = markFunction(getSessionData, 'offsd');

const SEVEN_DAYS = 7 * 24 * 60 * 60 * 1000;

async function getSessionData(): Promise<SessionData> {
    if (!isRunningInMetaOSHub()) {
        throw new Error('Not running in MetaOS Hub');
    }

    const metaOSContext = await lazyGetContext.importAndExecute();

    if (!metaOSContext?.user?.id || !metaOSContext?.user?.tenant?.id) {
        const error: TraceErrorObject = new Error('MetaOS context is not available');
        error.additionalInfo = {
            userId: metaOSContext?.user?.id ?? null,
            tenantId: metaOSContext?.user?.tenant?.id ?? null,
        };
        throw error;
    }

    const ownerId = `${metaOSContext.user.id}@${metaOSContext.user.tenant.id}`;

    const placesSessionData = await placesSessionDataTable.get({ ownerId });

    if (!placesSessionData?.sessionData) {
        // If we don't have session data, we should reject the promise
        // to make sure that network session data wins
        throw new Error('Could not load session data from Places offline store');
    }

    const cachedTimestamp = placesSessionData?.timestamp ?? 0;
    const isSessionDataStale = cachedTimestamp < Date.now() - SEVEN_DAYS;
    if (placesSessionData?.sessionData && isSessionDataStale) {
        const error: TraceErrorObject = new Error('Session data is stale. exceeded 7 days.');
        error.additionalInfo = {
            ownerId,
            cachedTimestamp,
        };
        throw error;
    }

    return {
        isOffline: true,
        ...placesSessionData.sessionData,
    };
}
