import msalConfiguration from 'hybridspace-common/lib/constants/msalConfiguration';
import { logAppBootStep } from 'hybridspace-telemetry/lib/logAppBootStep';
import { PassiveMonitoringAriaTenantToken } from 'owa-analytics-start';
import { loadDataWorker } from 'owa-data-worker-loader';
import { start } from 'owa-start';
import { lazyBootstrap } from './lazyBootFunctions';
import { onFatalBootError } from './onFatalBootError';
import { overrideBootPromises } from './overrideBootPromises';

// TODO: Consider making this be lazy-loaded
// Currently, the `hybridspace-telemetry` package is being bundled in the `placesindex` bundle
logAppBootStep('AppStart');

const app = 'Places';

start({
    app,
    loadBpos: true,
    startupAriaToken: PassiveMonitoringAriaTenantToken(), // OWA Passive Monitoring
    msalConfiguration,
    runAfterMetaOsAppInitialized: (hostHubName, clientType, userOid, tenantId) => {
        logAppBootStep('MetaOSInitializeEnd', { hostHubName, clientType, userOid, tenantId });
    },
    runBeforeStart: async () => {
        try {
            logAppBootStep('WorkerStart');
            loadDataWorker();
        } catch {
            // do not break boot for data worker load failure
        }
    },
    bootstrap: lazyBootstrap,
    onFatalBootError,
    overrideBootPromises: authToken => overrideBootPromises(app, authToken),
});
