import { markAppBootStep } from 'hybridspace-telemetry/lib/logAppBootStep';
import { LazyAction, LazyBootModule } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => {
        return markAppBootStep(
            import(/* webpackChunkName: "PlacesBoot" */ './bootFunctions'),
            'PlacesBootBundle'
        );
    },
    { name: 'PlacesBoot' }
);

export const lazyBootstrap = new LazyAction(lazyModule, m => m.bootstrap);
