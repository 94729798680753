import type { SessionData } from 'owa-service/lib/types/SessionData';
import { isRunningInMetaOSHub } from 'owa-config';
import { lazyGetContext } from 'owa-metaos-app-bootstrap';
import { placesSessionDataTable } from './sessionDataDatabase';

/**
 * lightweight version compared to OWA's saveOfflineSessionData as Places doesn not need Mail/Calendar offline features
 * Extracted core logic from packages/offline/owa-offline-session-data/src/saveOfflineSessionData.ts
 */
export async function saveOfflineSessionData(networkSD: SessionData): Promise<void> {
    if (!isRunningInMetaOSHub() || !networkSD || networkSD.isOffline) {
        return;
    }

    const metaOSContext = await lazyGetContext.importAndExecute();

    if (!metaOSContext?.user?.tenant?.id || !metaOSContext?.user?.id) {
        return;
    }

    const ownerId = `${metaOSContext.user.id}@${metaOSContext.user.tenant.id}`;

    await placesSessionDataTable.put({
        ownerId,
        sessionData: {
            owaUserConfig: networkSD.owaUserConfig,
            features: networkSD.features,
            skipBuilds: networkSD.skipBuilds,
            extraSettings: networkSD.extraSettings,
            applicationSettings: networkSD.applicationSettings,
        },
        timestamp: Date.now(),
    });
}
