import { logAppBoot } from 'hybridspace-telemetry';
import { logAppBootStep } from 'hybridspace-telemetry/lib/logAppBootStep';
import { placesAnalyticsAppLoadAction } from 'places-fwk-actions';
import { PlacesDashboardLoadE2EComplete } from './placesDashboardLoadE2E';
import { PlacesHomepageV3LoadE2EComplete } from './placesHomepageV3LoadE2E';

export default function placesFwkBootFatalBootAction(errorReason = 'FatalBootError') {
    // fail Places PLTs when boot error occurs
    PlacesDashboardLoadE2EComplete(false, errorReason);
    PlacesHomepageV3LoadE2EComplete(false, errorReason);
    placesAnalyticsAppLoadAction({
        scenario: 'FatalBootError',
        errorSource: errorReason,
    });

    logAppBoot(
        undefined /** loadedPage */,
        false /** ActionSuccess */,
        errorReason /** ActionFailureInfo */
    );

    logAppBootStep(
        'AppBootEnd' /** eventName */,
        { hasError: true, isFatalError: true },
        errorReason
    );
}
