import { logGreyError } from 'owa-analytics';
import { isRunningInMetaOSHub } from 'owa-config';
import { lazyGetContext } from 'owa-metaos-app-bootstrap';

let cache: string | undefined;

export function refetchUserSessionFromMetaOSContext() {
    cache = undefined;
    return getUserSessionFromMetaOSContext();
}

/**
 * Fetches the user session from the MetaOS context.
 *
 * Note: Never throw an error in this function. If the function fails, it will return `undefined`.
 */
export default async function getUserSessionFromMetaOSContext() {
    try {
        if (!isRunningInMetaOSHub()) {
            return undefined;
        }

        if (!cache) {
            const context = await lazyGetContext.importAndExecute();

            cache = JSON.stringify({
                app: {
                    locale: context.app.locale,
                    theme: context.app.theme,
                    sessionId: context.app.sessionId,
                    host: context.app.host,
                    appLaunchId: context.app.appLaunchId,
                    appId: context.app.appId?.toString(),
                    manifestVersion: context.app.manifestVersion,
                },
                user: {
                    id: context.user?.id,
                    loginHint: context.user?.loginHint,
                    userPrincipalName: context.user?.userPrincipalName,
                    tenant: context.user?.tenant,
                },
                page: context.page,
            });
        }

        return cache;
    } catch (err) {
        logGreyError('getUserSessionFromMetaOSContext', err);
        return undefined;
    }
}
