import { logStartCoreUsage, logStartGreyError } from 'owa-analytics-start';
import { isRunningInMetaOSHub } from 'owa-config';
import { isBootFeatureEnabled } from 'owa-metatags';
import { getHostLocation } from 'owa-url/lib/hostLocation';
import { PLACES_APP_START_TIME, PLACES_TELEMETRY_TRACKING_ID } from './constants';
import getEntryPoint, { getMetaOsEntryPoint } from './utils/getEntryPoint';
import getUserSessionFromMetaOSContext from './utils/getUserSessionFromMetaOSContext';

import type { CustomDataMap } from 'owa-analytics-types';
import type { WithSuffix } from './types';
import type { TraceErrorObject } from 'owa-trace';

const STEP_LIST = [
    /**
     * Phase 1
     */
    'AppStart',
    'MetaOSImportEnd',
    'MetaOSInitializeEnd',
    'WorkerStart',

    /**
     * Phase 2
     */
    'SessionDataStart',
    'SessionDataEnd',
    'PlacesBootBundleStart',
    'PlacesBootBundleEnd',
    'MainModuleBundleStart',
    'MainModuleBundleEnd',

    /**
     * Phase 3
     */
    'InitializeState',
    'PlacesSettingsStart',
    'PlacesSettingsEnd',
    'OfflinePlacesSettingsStart',
    'OfflinePlacesSettingsEnd',

    /**
     * Phase 4
     */
    'Render',
    'AppBootEnd',
] as const;

export type PlacesAppBootStep = typeof STEP_LIST[number];

/**
 * Why not use PerformanceDatapoint Waterfall?
 * We want to send the logs out ASAP
 * to avoid losing them when the WINDOW CLOSES SUDDENLY.
 */
export function logAppBootStep(
    step: PlacesAppBootStep,
    customData?: Readonly<CustomDataMap>,
    error?: unknown
) {
    if (!isEnabled()) {
        return;
    }

    collectAppBootStepInfo(step, customData, error).then(customData2 => {
        if (customData2) {
            logStartCoreUsage('PlacesAppBootE2E', customData2);
        }
    });
}

export async function collectAppBootStepInfo(
    step: PlacesAppBootStep,
    customData?: Readonly<CustomDataMap>,
    error?: unknown
): Promise<CustomDataMap | undefined> {
    try {
        const now = performance.now();
        const elapsedTime = now - PLACES_APP_START_TIME;

        const [metaOsEntryPoint, metaOsContext] = await (isRunningInMetaOSHub()
            ? Promise.all([getMetaOsEntryPoint(), getUserSessionFromMetaOSContext()])
            : Promise.resolve([undefined, undefined]));

        let reason: string | undefined;
        let diagnostics: string | undefined;

        try {
            if (error instanceof Error) {
                reason = error.message;
                diagnostics = JSON.stringify({
                    ...(error as TraceErrorObject).additionalInfo,
                    diagnosticInfo: (error as TraceErrorObject).diagnosticInfo,
                });
            } else if (typeof error === 'string') {
                reason = error;
            } else {
                reason = (error as Error | undefined)?.message;
            }
        } catch {}

        return {
            step_track_id: PLACES_TELEMETRY_TRACKING_ID,
            step_time: now,
            step_elapsed_time: elapsedTime,
            step_name: step,
            step_order: STEP_LIST.indexOf(step) + 1,
            step_path: getHostLocation().pathname,
            step_entry_point: getEntryPoint(),
            step_meta_os_entry_point: metaOsEntryPoint,
            step_meta_os_context: metaOsContext,
            error: reason,
            diagnostics,
            ...customData,
        };
    } catch (err) {
        logStartGreyError('PlacesAppBootE2EError', err, {
            step_track_id: PLACES_TELEMETRY_TRACKING_ID,
            step_name: step,
            step_order: STEP_LIST.indexOf(step) + 1,
        });
        return undefined;
    }
}

type AsyncStep = Extract<
    WithSuffix<PlacesAppBootStep, 'Start'>,
    WithSuffix<PlacesAppBootStep, 'End'>
>;

export function startAppBootStep(asyncStep: AsyncStep, customData?: Readonly<CustomDataMap>) {
    const startTime = performance.now();
    logAppBootStep(`${asyncStep}Start`, customData);

    return {
        hasError: false,
        caughtError: undefined as unknown | undefined,
        end(customData2?: Readonly<CustomDataMap>) {
            logAppBootStep(
                `${asyncStep}End`,
                {
                    ...customData,
                    ...customData2,
                    hasError: this.hasError,
                    duration: performance.now() - startTime,
                },
                this.caughtError
            );
        },
    };
}

export async function markAppBootStep<T>(
    promise: Promise<T>,
    asyncStep: AsyncStep,
    customData?: Readonly<CustomDataMap>
) {
    if (!isEnabled()) {
        return promise;
    }

    const bootStep = startAppBootStep(asyncStep, customData);

    try {
        return await promise;
    } catch (err) {
        bootStep.hasError = true;
        bootStep.caughtError = err;
        throw err;
    } finally {
        bootStep.end();
    }
}

function isEnabled() {
    return isBootFeatureEnabled('msplaces-app-boot-sequence');
}
