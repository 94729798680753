import { logAppBootStep, startAppBootStep } from 'hybridspace-telemetry/lib/logAppBootStep';
import { logStartGreyError } from 'owa-analytics-start';
import { runAfterInitialRender } from 'owa-bundling-light/lib/utils/delayLoad';
import { GovernPriority } from 'owa-client-types/lib/GovernPriority';
import { isRunningInMetaOSHub } from 'owa-config/lib/isRunningInMetaOSHub';
import { lazySaveOfflineSessionData as lazySaveOwaOfflineSessionData } from 'owa-data-worker-client';
import { getOfflineSessionData as getOwaOfflineSessionData } from 'owa-data-worker-client/lib/actions/getOfflineSessionData';
import { lazyModule as metaOSAppBootstrapModule } from 'owa-metaos-app-bootstrap';
import { isBootFeatureEnabled } from 'owa-metatags';
import { isMsalFlowEnabled } from 'owa-msaljs/lib/isMsalFlowEnabled';
import { networkRace } from 'owa-offline/lib/networkRace';
import { addBootCustomData } from 'owa-performance';
import getSessionData from 'owa-shared-start/lib/getSessionData';
import {
    getOfflineSessionData as getPlacesOfflineSessionData,
    saveOfflineSessionData as savePlacesOfflineSessionData,
} from 'places-user-session-stores/lib/sessionDatabase';

import type { SessionData } from 'owa-service/lib/types/SessionData';
import type { CustomDataMap } from 'owa-analytics-types';

/**
 * For V0, we're using OWA's offline session data infrastructure via owa-data-worker-client getOfflineSessionData, driven by msplaces-offline feature flight and msplaces-offline-sync-engine boot flight.
 * This has been working for initial bootstrapping of Places, however it has a couple drawbacks:
 *    - Worker thread is shared with all OWA apps, based on discussion with OWA Framework, it's not ideal to create forks of syncs in owa-offline-sync-manager/src/syncManager.ts.
 *    - We are calling Mail/Calendar related syncs which are unnecessary for Places and Places is not pre-authenticated with some of the downstream API.
 *
 * For V1, we're using Places' own offline session data infrastructure via places-user-session-stores saveOfflineSessionData, driven by msplaces-hosted-localsessiondata boot flight.
 * This is a lightweight version of OWA's offline sync, where we only store the necessary session data for Places to boot.
 *    - We'll store user config and user settings.
 *    - This is also only enabled in MetaOS for V1 as we are able to retrive user context (oid&tid) from the host
 *
 * For V2, same as V1, but we will use a new "msplaces-hosted-localsessiondata-v2" boot flight to avoid regression in older versions of the Places web app.
 *    - Check active versions: https://dataexplorer.azure.com/clusters/kusto.aria.microsoft.com/databases/56468f6991c348029c6bba403b444607?query=H4sIAAAAAAAAA12OwQrCMAyG7z5FPHWDHgTPO4h6EFREhtfRddkMtM1oO0Xx4V13GOIlgfxfvkQbQhcrfIx18YHnHT3ClVwHS3KQiR13LXMjJIgTac%2BB2yjymdz0PRQFiItRGoOY5%2FvkOyuLP%2BnIHlk1CQqDtcrTG6HkqMyWBxehAJ16lkP9gppcNkkOruWqJIsS1qsml9NzMh2ekhv6QOySk31Mm38JqKC%2FQWgUU%2BUAAAA%3D
 */
export async function overrideBootPromises(app: string, authToken?: string): Promise<SessionData> {
    const hostedLocalSessionDataBootFlightEnabled = isBootFeatureEnabled(
        'msplaces-hosted-localsessiondata-v2'
    );

    const offlineSyncEngineBootFlightEnabled = isBootFeatureEnabled('msplaces-offline-sync-engine');

    const isRunningOnMetaOS = isRunningInMetaOSHub();

    const customData: CustomDataMap = {
        hostedLocalSessionDataBootFlightEnabled,
        offlineSyncEngineBootFlightEnabled,
        isRunningOnMetaOS,
        isMsalFlowEnabled: isMsalFlowEnabled(),
        hasAuthToken: !!authToken,
    };

    const bootStep = startAppBootStep('SessionData', customData);

    try {
        // If boot flights are not enabled, we don't need to read local session data via sync engine (OWA's indexdb) or Places' indexdb
        if (!hostedLocalSessionDataBootFlightEnabled && !offlineSyncEngineBootFlightEnabled) {
            return await getSessionData();
        }

        const networkSDPromise = getSessionData(authToken);

        // Based on function documentation. for web, we can't read the right indexdb for a given user as we don't have the user context yet.
        // it is an opportunity to read from token in the future as we would need that for startupdata call. For now, it's scoped to metaos.
        if (!isRunningOnMetaOS) {
            return await networkSDPromise;
        }

        return await networkRace<SessionData>(
            hostedLocalSessionDataBootFlightEnabled ? 'PlacesSessionData' : 'SessionData',
            () => networkSDPromise,
            () => {
                // Since we have msplaces-offline-sync-engine and msplaces-hosted-localsessiondata-v2 check at line 57.
                // Only checking one of the flight here, and using the other one as the fallback.
                const offlineSDPromise = hostedLocalSessionDataBootFlightEnabled
                    ? getPlacesOfflineSessionData()
                    : getOwaOfflineSessionData(app);
                return offlineSDPromise.catch(err => {
                    customData.hasGetOfflineError = true;
                    logStartGreyError('PlacesGetOfflineSessionDataError', err, customData);
                    // Since the "raceCompleteCallback" requires all promises to be resolved,
                    // we have to catch the error here and fall back to the network call.
                    return networkSDPromise;
                });
            },
            /** raceCompleteCallback */ (networkSD, offlineSD, _winner) => {
                runAfterInitialRender(() => {
                    const saveOfflineSDPromise = hostedLocalSessionDataBootFlightEnabled
                        ? savePlacesOfflineSessionData(networkSD)
                        : lazySaveOwaOfflineSessionData.importAndExecute(networkSD, offlineSD);
                    return saveOfflineSDPromise.catch(err => {
                        customData.hasSaveOfflineError = true;
                        logStartGreyError('PlacesSaveOfflineSessionDataError', err, customData);
                    });
                }, GovernPriority.Default);
            },
            0 /** networkTimeout */,
            false /** shouldUseNetworkOnly */,
            false /** shouldUseOfflineOnly */
        ).then(({ value, customData: customData2, winner }) => {
            addBootCustomData('SessionDataNetworkRace', customData2);
            Object.assign(customData, customData2, { winner });
            return value;
        });
    } catch (err) {
        bootStep.hasError = true;
        bootStep.caughtError = err;
        logStartGreyError('PlacesSessionDataError', err, customData);
        throw err;
    } finally {
        bootStep.end(customData);
    }
}

// Try to import the Teams SDK as soon as possible to avoid timeout in initializing the Places app from Teams
if (isRunningInMetaOSHub()) {
    metaOSAppBootstrapModule
        .importModule('PlacesPreloadMetaOSAppSdk')
        ?.then(mod => {
            logAppBootStep('MetaOSImportEnd', {
                initializeMetaOsAppSdk: typeof mod?.initializeMetaOsAppSdk,
            });
        })
        ?.catch(err => {
            logStartGreyError('PlacesPreloadMetaOSAppSdkError', err);
        });
}
